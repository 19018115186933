import type { Permission } from '@orus.eu/right-access-management'
import { useParams } from '@tanstack/react-router'
import { useSession } from './session'

export const usePermissions = (): {
  type: ReturnType<typeof useSession>['permissions']['type']
  permissions: Permission[]
  organization: { technicalName: string; displayName: string } | undefined
} => {
  const { organization } = useParams({ strict: false })
  const { permissions } = useSession()

  switch (permissions.type) {
    case 'client':
    case 'platform':
      return {
        type: permissions.type,
        permissions: permissions.rolesPermissions.flatMap(({ permissions }) => permissions),
        organization: undefined,
      }
    case 'partner':
      return {
        type: permissions.type,
        organization: permissions.memberships.find(
          ({ organization: { technicalName } }) => organization === technicalName,
        )?.organization,
        permissions:
          permissions.memberships
            .find(({ organization: { technicalName } }) => organization === technicalName)
            ?.rolesPermissions.flatMap(({ permissions }) => permissions) ?? [],
      }
  }
}
